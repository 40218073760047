import Nav from './Nav'
import Footer from './Footer'

export default function Layout({ children }) {
  return (
    <>
      <Nav />
      <main className="container mx-auto mt-10 grow px-6 2xl:max-w-[1280px]">
        {/* <div className="mx-auto mb-4 rounded bg-green-600 p-2 py-4 pl-4 text-white">
          <p>
            Holiday Hours: Friday 12/23 9am to 2pm. 12/26 thru 12/30 9am to 2pm.
            Back to normal on 1/2/2023.
          </p>
          <p>Merry Christmas and Happy New Year!</p>
        </div> */}
        {children}
      </main>
      <Footer />
    </>
  )
}
