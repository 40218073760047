// {
//     title: '',
//     description: '',
//     date:  '',
//     url:  '',
//     type: '',
// },

module.exports = [
  {
    title:
      'Price increase on styrene mounting for odd sizes like 10x10, 9x12 and 11x17.',
    description: '',
    date: 'May 9, 2024',
    url: '',
    type: '',
  },
  {
    title: 'Collage Prints now available.',
    description: '',
    date: 'December 1, 2023',
    url: '/photo-collages/',
    type: '',
  },
  {
    title: 'Mounting on Foam Core no longer available.',
    description: '',
    date: 'April 20, 2023',
    url: '',
    type: '',
  },
  {
    title: 'Pro Pricing Luster pricing has been updated.',
    description: '',
    date: 'November 6, 2022',
    url: '',
    type: '',
  },
  {
    title: 'Full Service Luster pricing has been updated.',
    description: '',
    date: 'November 4, 2022',
    url: '',
    type: '',
  },
  {
    title: 'Wanted: Omni 16 Electrolytic Silver Recovery Unit',
    description:
      'We are looking for an electrolytic silver recovery unit, preferably an Omni 16, but we would be interested in another type if you have one available.',
    date: 'August 16, 2022',
    url: '/omni-16-silver-recovery-unit/',
    type: '',
  },
  {
    title:
      "We've added a Review page to our website, leave us a review if you have a spare bit of time!",
    description: '',
    date: 'July 12, 2022',
    url: '/review/',
    type: '',
  },
  {
    title:
      'We will be closed on Monday July 4th. Back to normal on Tuesday July 5th.',
    description: '',
    date: 'July 2, 2022',
    url: '',
    type: '',
  },
  {
    title: 'New website up and running!',
    description: '',
    date: 'July 2, 2022',
    url: '',
    type: '',
  },
  {
    title:
      "We've raised the price of some gator board sizes, prices just keep going up and up.",
    description: '',
    date: 'April 3, 2022',
    url: '',
    type: '',
  },
  {
    title:
      "We've raised our prices on mounting large prints - 16x20 and larger. Some of these increases were substantial due to us not changing prices recently and the recent increases in costs from our suppliers.",
    description: '',
    date: 'November 16, 2021',
    url: '',
    type: '',
  },
  {
    title: 'How to make a Triptych in Photoshop',
    description:
      'Simple way to split an image into panels to make a triptych..',
    date: 'September 17, 2016',
    url: '/videos/how-to-make-a-triptych-in-photoshop/',
    type: 'video',
  },
  {
    title: 'Photoshop Action to Split a File and Save as 2 Halves',
    description:
      'Creating an action to split a file into two halves, and save both halves as new files. Can be run using the Batch command to automate it and run on a folder of files.',
    date: 'May 16, 2014',
    url: '/videos/photoshop-action-to-split-a-full-spread-into-two-halves/',
    type: 'video',
  },
]
