import Link from 'next/link'
import NewsItems from '../news-items'

export default function News() {
  return (
    <>
      {NewsItems.slice(0, 25).map(({ title, date, url }) => (
        <div className="mb-4 border-b pb-4" key={title}>
          <p className="mb-1 text-xs">{date}</p>
          <p className="mb-1">{title}</p>
          {url && (
            <p className="mb-1 text-sm">
              <Link href={url} className=" text-blue-500 hover:text-blue-400">
               Click for more info
              </Link>
            </p>
          )}
        </div>
      ))}
    </>
  )
}
